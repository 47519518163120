.bannerLogoutContainer {
  @apply relative grid min-h-[19rem] w-full overflow-hidden rounded-moon-s-md bg-transparent p-0 [align-content:stretch] md:my-0 md:mx-auto md:min-h-[32rem] xl:rounded-none xl:rounded-t-moon-s-md landscape:height2xs:my-0 landscape:height2xs:mx-auto landscape:height2xs:min-h-[18rem];

  :global(.main-banner-container) {
    @apply relative z-2 grid w-[calc(100vw-2.5rem)] grid-rows-[auto_6.5rem] overflow-hidden rounded-moon-s-md md:h-full md:grid-rows-[initial] md:pl-2.5 xl:w-full xl:rounded-none xl:rounded-t-moon-s-md xl:pl-[10%] landscape:height2xs:h-full landscape:height2xs:grid-rows-[initial] landscape:height2xs:pl-2.5 landscape:height2xs:pb-4;
  }

  :global(.main-banner-container.logged_out) {
    @apply flex h-[initial] w-[initial] flex-col items-center justify-center gap-4 p-0 md:grid md:grid-cols-[auto] md:grid-rows-[1fr] xl:grid-cols-[1fr_auto];

    :global(.card) {
      @apply rounded-moon-s-md bg-white p-5;
    }

    :global(.main-banner-title) {
      @apply font-headline text-moon-24 font-normal text-bulma md:text-moon-32 xl:text-[1.75rem] xl:leading-8 2xl:text-[2.5rem] 2xl:leading-10 3xl:text-moon-48;
    }

    :global(.main-banner-subtitle) {
      @apply text-base font-normal text-bulma md:text-xl;
    }

    :global(.main-banner-logout-currency-info) {
      @apply text-moon-14 font-normal text-trunks;
    }

    :global(.main-banner-logged-out-button) {
      @apply mt-2 p-7 text-moon-16 font-normal;

      div {
        @apply flex items-center justify-center;
      }
    }

    :global(.main-banner-texts-logout) {
      @apply relative z-1 flex w-full max-w-xl flex-col items-center justify-center gap-2 self-center justify-self-auto p-0 text-center first:w-full md:content-center xl:gap-6 xl:p-0 xl:pr-4 2xl:p-0 2xl:pr-14 landscape:height2xs:max-w-[50%] landscape:height2xs:content-center;

      h4 {
        @apply -mt-1 text-moon-12 md:text-moon-14;
      }

      div {
        @apply order-1 w-full;
      }
    }
  }

  :global(.main-banner-texts) {
    @apply relative z-1 grid max-w-xl gap-4 self-center justify-self-start p-0 [text-align:initial] md:content-center xl:gap-4 xl:p-0 xl:pr-4 2xl:p-0 2xl:pr-14 landscape:height2xs:max-w-[50%] landscape:height2xs:content-center;

    h4 {
      @apply text-moon-14;
    }
  }

  :global(.main-banner-cards) {
    @apply grid w-full grid-cols-[1fr_1fr_1fr_1fr] justify-items-center gap-3 overflow-x-auto scroll-hidden ltr:-mx-5 ltr:-my-0 rtl:m-0 xl:m-0 [&_a]:no-underline;
  }

  :global(.main-banner-cards-row) {
    padding: 0 !important;
    @apply grid grid-cols-[1fr_1fr] items-center justify-center gap-3 first:gap-0 last:gap-0 first:ltr:ml-5 last:ltr:mr-5 first:rtl:ml-0 last:rtl:mr-0 xl:m-0 xl:flex xl:flex-col xl:gap-4 xl:first:ml-0 xl:last:pr-0;
  }

  :global(.main-banner-card-container) {
    @apply flex h-72 w-[12.5rem] flex-col justify-between rounded-moon-s-sm p-5 xl:h-56 xl:w-40 xl:p-3.5 2xl:h-[17rem] 2xl:w-48 2xl:p-5 [&_img]:max-h-[8.6rem] [&_img]:max-w-[10rem];
  }

  :global(.main-banner-card-texts) {
    @apply flex flex-col justify-end;

    h4 {
      @apply m-0 mb-1 text-moon-16 font-semibold text-gohan;
    }

    h5 {
      @apply m-0 mb-1 text-moon-12 font-normal text-gohan opacity-60;
    }
  }
}
